/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5geodata_lang_pt_BR from '@amcharts/amcharts5-geodata/lang/PT';
import am5geodata_data_countries2 from '@amcharts/amcharts5-geodata/data/countries2';
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Home as HomeIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useContext, useLayoutEffect } from 'react';
import MapChartSkeleton from '../../../components/MapChartSkeleton';
import Context from '../../../Context';
import getFolderMapChart from '../services/getFolderMapChart';
import getMapInfo from '../services/getMapInfo';
import { TotalViewsByState } from '../types';

interface Props {
  isDir: boolean;
}

interface StatesWithValue {
  [key: string]: number;
}

const MapChart = ({ isDir }: Props) => {
  const { dateRange, itemId, selectedAccountId } = useContext(Context);
  const { isLoading, data } = useQuery([dateRange, itemId], async () => {
    const request = isDir
      ? getFolderMapChart(itemId, dateRange, selectedAccountId)
      : getMapInfo(itemId, dateRange);
    return await request;
  });
  const { palette } = useTheme();
  const startColor = palette.primary.main;
  const defaultColor = palette.primary[50];
  const endColor = palette.primary.dark;
  const getCoutriesWithTheierValues = (
    data: TotalViewsByState,
  ): StatesWithValue => {
    const countriesWithValue: StatesWithValue = {};
    Object.entries(data.total).forEach(
      ([country, states]: [string, StatesWithValue]) => {
        let totalSumOfStates = 0;
        Object.values(states).forEach((stateValue: number) => {
          totalSumOfStates += stateValue;
        });
        countriesWithValue[country] = totalSumOfStates;
      },
    );
    return countriesWithValue;
  };
  const getValueByState = (
    data: TotalViewsByState,
    country: string,
    state: string,
  ): number => {
    const countryData = data.total[country];
    if (!countryData) return 0;
    const stateKey = state.replace(`${country}-`, '');
    const stateValue = countryData[stateKey];
    return stateValue || 0;
  };
  useLayoutEffect(() => {
    let root: am5.Root;
    if (data) {
      const coutriesWithTotals = getCoutriesWithTheierValues(data);
      root = am5.Root.new('chartdiv');
      if (root._logo != null) {
        root._logo.dispose();
      }
      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: 'rotateX',
          projection: am5map.geoEqualEarth(),
        }),
      );
      const countrySeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow,
          geodataNames: am5geodata_lang_pt_BR,
          valueField: 'value',
          exclude: ['AQ'],
          calculateAggregates: true,
          fill: am5.color(defaultColor),
          stroke: am5.color(0xffffff),
        }),
      );

      countrySeries.mapPolygons.template.setAll({
        tooltipText: '{name}: {value}',
        interactive: true,
      });

      const countryData = [];
      for (const id in am5geodata_data_countries2) {
        const country = am5geodata_data_countries2[id];
        if (country.maps.length) {
          countryData.push({
            id,
            value: coutriesWithTotals[id] || 0,
            map: country.maps[0],
          });
        }
      }

      const stateSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          valueField: 'value',
          fill: am5.color(defaultColor),
          calculateAggregates: true,
          visible: false,
          stroke: am5.color(0xffffff),
        }),
      );

      stateSeries.mapPolygons.template.setAll({
        tooltipText: '{name}: {value}',
        interactive: true,
        templateField: 'polygonSettings',
      });

      const backButton = document.querySelector('.back') as HTMLElement;
      const hideBackButton = () => {
        if (backButton) {
          backButton.style.display = 'none';
        }
      };
      const showBackButton = () => {
        if (backButton) {
          backButton.style.display = 'flex';
        }
      };
      countrySeries.mapPolygons.template.events.on(
        'click',
        (ev: { target: am5map.MapPolygon }) => {
          const dataItem = ev.target
            .dataItem as am5.DataItem<am5map.IMapPolygonSeriesDataItem>;
          if (dataItem) {
            const dataContext = dataItem.dataContext as any;
            const zoomAnimation = countrySeries.zoomToDataItem(dataItem);
            if (dataContext && zoomAnimation) {
              const selectedCountry = dataContext?.id;
              void Promise.all([
                zoomAnimation.waitForStop(),
                am5.net.load(
                  `https://cdn.amcharts.com/lib/5/geodata/json/${dataContext.map}.json`,
                  chart,
                ),
              ]).then(async (results) => {
                if (results[1]?.response) {
                  const geodata = am5.JSONParser.parse(results[1].response);
                  const statesWithValues = geodata.features.map(
                    (item: { id: string }) => ({
                      id: item.id,
                      value: getValueByState(data, selectedCountry, item.id),
                    }),
                  );

                  stateSeries.setAll({
                    geoJSON: geodata,
                    valueField: 'value',
                  });

                  stateSeries.set('heatRules', [
                    {
                      target: stateSeries.mapPolygons.template,
                      dataField: 'value',
                      min: am5.color(startColor),
                      max: am5.color(endColor),
                      key: 'fill',
                    },
                  ]);

                  stateSeries.data.setAll(statesWithValues);

                  await stateSeries.show();
                  await countrySeries.hide(100);
                  showBackButton();
                }
              });
            }
          }
        },
      );

      countrySeries.set('heatRules', [
        {
          target: countrySeries.mapPolygons.template,
          dataField: 'value',
          min: am5.color(startColor),
          max: am5.color(endColor),
          key: 'fill',
        },
      ]);

      countrySeries.data.setAll(countryData);

      if (backButton) {
        backButton.addEventListener('click', () => {
          chart.goHome();
          void countrySeries.show();
          void stateSeries.hide();
          hideBackButton();
        });
      }
    }
    return () => root?.dispose();
  }, [data]);
  if (isLoading || !data) return <MapChartSkeleton />;
  return (
    <Card>
      <CardContent>
        <Typography variant="body1" sx={{ color: 'neutral.50' }}>
          Total de visualizações por estado
        </Typography>
        <Box
          id="chartdiv"
          sx={{
            bgcolor: 'neutral.light',
            height: 369,
            mt: 2,
            position: 'relative',
            width: '100%',
          }}
        >
          <Tooltip title="Voltar" placement="top">
            <Button
              color="primary"
              variant="outlined"
              className="back"
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'none',
                height: 30,
                position: 'absolute',
                right: 10,
                top: 10,
                width: 36,
                minWidth: 36,
                zIndex: 1,
              }}
            >
              <HomeIcon sx={{ color: 'primary.main' }} />
            </Button>
          </Tooltip>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MapChart;
